import React, { useMemo } from 'react';
import { Box, Input, Select, Chip, IconButton, Tooltip, Checkbox, ListItem, Option } from '@mui/joy';
import { ReactComponent as SelectObjectIcon } from '@/public/icons/select-object.svg';
import { ReactComponent as FilterIcon } from '@/public/icons/filter.svg';
import { ReactComponent as CloseIcon } from '@/public/icons/close.svg';
import { ReactComponent as CubeIcon } from '@/public/icons/cube.svg';
import { ReactComponent as TextIcon } from '@/public/icons/text.svg';
import { ReactComponent as ImageIcon } from '@/public/icons/image.svg';
import { ReactComponent as CursorClickIcon } from '@/public/icons/cursor-click.svg';
import { ReactComponent as CursorTypingIcon } from '@/public/icons/cursor-typing.svg';

const PARAMETER_TYPES = {
	object: {
		label: 'Object',
		color: 'success',
		icon: <CubeIcon fill='currentColor' width={12} height={12} />,
	},
	text: {
		label: 'Text',
		color: 'neutral',
		icon: <TextIcon fill='currentColor' width={12} height={12} />,
	},
	image: {
		label: 'Image',
		color: 'danger',
		icon: <ImageIcon fill='currentColor' width={12} height={12} />,
	},
	click: {
		label: 'Click',
		color: 'primary',
		icon: <CursorClickIcon fill='currentColor' width={12} height={12} />,
	},
	input: {
		label: 'Input',
		color: 'warning',
		icon: <CursorTypingIcon fill='currentColor' width={12} height={12} />,
	},
	// number: {
	// 	label: 'Number',
	// 	color: 'primary',
	// },
	// boolean: {
	// 	label: 'Boolean',
	// 	color: 'warning',
	// },
};

interface ParameterInputProps {
	parameter: any;
	onChange: (value: any) => void;
	onRemove: () => void;
	onAddFilter: () => void;
	onSelectElement: () => void;
	selectedValue: any;
	isActive: boolean;
}

const ParameterInput = (props: ParameterInputProps) => {
	const { parameter, onChange, onRemove, onAddFilter, onSelectElement, selectedValue, isActive } = props;

	return (
		<Box display='flex' alignItems='center'>
			<Input
				size='sm'
				variant='plain'
				sx={{
					flexGrow: 1,
					paddingLeft: 0,
					borderRadius: 0,
					'&:before': {
						boxShadow: 'none',
					},
				}}
				placeholder='name'
				autoFocus
				value={parameter.name}
				onChange={(e) => onChange({ name: e.target.value })}
				required
				slotProps={{
					startDecorator: {
						sx: {
							margin: 0,
						},
					},
				}}
				startDecorator={
					<>
						<Select
							sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, overflow: 'hidden' }}
							value={parameter.type}
							size='sm'
							variant='plain'
							color='neutral'
							onChange={(e, newValue) => onChange({ type: newValue })}
							indicator={null}
							renderValue={({ value }) => {
								return <Chip startDecorator={PARAMETER_TYPES[value].icon ? PARAMETER_TYPES[value].icon : null} size='sm' variant='soft' color={PARAMETER_TYPES[value].color}>{PARAMETER_TYPES[value].label}{parameter.isArray && ' []'}</Chip>; }}
						>
							<ListItem key='array'>
								<Checkbox label='Is Array' size='sm' checked={parameter.isArray} onChange={(e) => onChange({ isArray: e.target.checked })} />
							</ListItem>
							{Object.keys(PARAMETER_TYPES).map((type) => (
								<Option value={type} key={type}>
									<Chip startDecorator={PARAMETER_TYPES[type].icon ? PARAMETER_TYPES[type].icon : null} size='sm' variant='soft' color={PARAMETER_TYPES[type].color}>{PARAMETER_TYPES[type].label}</Chip>
								</Option>
							))}
						</Select>
					</>
				}
				endDecorator={
					isActive && (
						<>
							{selectedValue && (
								<Tooltip size='sm' title='Select Element'>
									<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onSelectElement}>
										<SelectObjectIcon />
									</IconButton>
								</Tooltip>
							)}
							<Tooltip size='sm' title='Filter'>
								<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onAddFilter}>
									<FilterIcon />
								</IconButton>
							</Tooltip>
							<Tooltip size='sm' title='Remove'>
								<IconButton size='sm' variant='plain' color='neutral' sx={{ margin: 0, minWidth: '24px', minHeight: '24px', paddingX: '5px' }} onClick={onRemove}>
									<CloseIcon width={10} height={10} />
								</IconButton>
							</Tooltip>
						</>
					)
				}
			/>
		</Box>
	);
};

export default ParameterInput;
