import styled from '@emotion/styled';
import { Box, List, Typography, ListItem, ListItemButton, Button, Avatar } from '@mui/joy';
import React, { useContext } from 'react';
import { ReactComponent as LogoIcon } from '@/public/icon.svg';
import { ReactComponent as UsageIcon } from '@/public/icons/usage.svg';
import { ReactComponent as IntegrationsIcon } from '@/public/icons/integrations.svg';
import { ReactComponent as FlowIcon } from '@/public/icons/flow.svg';
import { ReactComponent as PlayIcon } from '@/public/icons/play.svg';
import { ReactComponent as HomeIcon } from '@/public/icons/home.svg';
import { ReactComponent as SettingsIcon } from '@/public/icons/settings.svg';
import { ReactComponent as WebIcon } from '@/public/icons/web.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { UserContext } from '../context/UserContext';


const Container = styled(Box)`
  width: 100%;
  background-color: #fafafa;
  height: 100vh;
  border-right: 1px solid #e4e4e4;
  display: flex;
  flex-direction: column;
`;

const ListContainer = styled(Box)`

`;

const BottomListContainer = styled(Box)`
`;

const BottomSection = styled(Box)`
  display: flex;
	align-items: flex-end;
	flex-grow: 1;
`;

const ListButton = styled(ListItemButton)`
  font-weight: 500;
  font-size: 16px;
`;

const SideNavigation: React.FC = () => {
	const currentPath = useLocation().pathname;
	const { user } = useContext(UserContext);

	return (
		<Container>
			<Box p={3} pt={3} display='flex' gap={2} justifyContent='space-between' alignItems='center'>
				<a href='/' style={{ display: 'flex',  gap: '8px', alignItems: 'center' }}>
					<LogoIcon style={{ borderRadius: '5px' }} width='28px' height='28px' />
					<Typography fontFamily='"Source Code Pro", monospace' fontSize='18px' fontWeight='bold' level='title-md'>shinpads.dev</Typography>
				</a>
				<Typography mt={0.5} level='body-xs' color='neutral'>
          v1.1.12
				</Typography>
			</Box>
			<ListContainer p={2} pt={0}>
				<NavLink to='/builder'>
					<Button sx={{ marginBottom: '1rem' }} fullWidth variant='shadowed' color='secondary'>
						Create Automation
					</Button>
				</NavLink>
				<List>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/' selected={currentPath === '/'}>
							<HomeIcon width='18px' height='18px' fill='currentColor' />
							Dashboard
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/web-flows' selected={currentPath === '/web-flows'}>
							<FlowIcon width='18px' height='18px' fill='currentColor' />
							Web Flows
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/web-flow-runs' selected={currentPath === '/web-flow-runs'}>
							<UsageIcon width='18px' height='18px' fill='currentColor' />
							Web Flow Runs
						</ListItemButton>
					</ListItem>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/sessions' selected={currentPath === '/sessions'}>
							<WebIcon width='18px' height='18px' fill='currentColor' />
							Browser Sessions
						</ListItemButton>
					</ListItem>
				</List>
			</ListContainer>

			<BottomListContainer p={2} pt={0}>
				<List>
					<ListItem>
						<ListItemButton sx={{ fontWeight: 500, fontSize: '16px' }} component={NavLink} to='/settings' selected={currentPath === '/settings'}>
							<SettingsIcon width='18px' height='18px' fill='currentColor' />
							Settings
						</ListItemButton>
					</ListItem>
				</List>
			</BottomListContainer>

			<BottomSection p={2}>
				<Box display='flex' gap={2} alignItems='center' maxWidth='100%'>
					<Avatar size='sm' src={user?.image_url} />
					<Typography sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}  level='body-md'>{user?.email}</Typography>
				</Box>
			</BottomSection>
		</Container>
	);
};

export default SideNavigation;
