import React, { useState, useContext, useEffect } from 'react';
import { Box, Breadcrumbs, Button, Chip, Divider, FormLabel, IconButton, Input, Link, Select, TabPanel, Tabs, Typography } from '@mui/joy';
import API from '@/src/api';
import Events from '../Events';
import { BuilderContext, Pages, SELECT_MODE } from '../BuilderContext';
import WebAction from '../components/WebAction';
import { ReactComponent as PlayIcon } from '@/public/icons/play.svg';
import { ReactComponent as SaveIcon } from '@/public/icons/save.svg';

interface WebFlowProps {
	webFlow: any;
}

const DEFAULT_WEB_FLOW = {
	name: 'New Web Flow',
	description: '',
	actions: [],
	parameters: [],
	icon_url: null,
};

export const WebFlow = ({ webFlow: initialWebFlow }: WebFlowProps) => {
	const { activeAction, setActiveAction, frameUrl, selectMode, setPage, browserSession, iconUrl } = useContext(BuilderContext);

	const [webFlow, setWebFlow] = useState({ ...(initialWebFlow || DEFAULT_WEB_FLOW) });
	const [testLoading, setTestLoading] = useState(false);


	useEffect(() => {
		if (!webFlow.actions.length) {
			setWebFlow({ ...webFlow, start_url: frameUrl, icon_url: iconUrl });
		}
	}, [frameUrl, iconUrl, webFlow.actions]);

	useEffect(() => {
		if (activeAction) {
			setActiveAction(webFlow.actions.find(a => a.id === activeAction.id));
		}
	}, [webFlow.actions]);


	useEffect(() => {
		function handleActionCreated(event) {
			console.log(event.detail);
			const action = event.detail;
			if (activeAction) {
				window.dispatchEvent(new CustomEvent(Events.SUB_ACTION_ADDED, { detail: action }));
			} else {
				setWebFlow({ ...webFlow, actions: [...webFlow.actions, action] });
				if (action?.type === 'object' || !activeAction) {
					setActiveAction(action);
				}
			}
		}

		window.addEventListener(Events.ON_CREATE_ACTION, handleActionCreated);

		return () => {
			window.removeEventListener(Events.ON_CREATE_ACTION, handleActionCreated);
		};
	}, [webFlow.actions, activeAction, webFlow]);


	const onSave = async (e) => {
		e.preventDefault();

		setTestLoading(true);
		console.log('saving', webFlow.name, webFlow.description, webFlow.actions, webFlow.parameters);
		try {
			const updatedWebFlow = await API.createWebFlow(webFlow);
			setWebFlow(updatedWebFlow);
		} catch (error) {
			console.warn(error);
		}
		setTestLoading(false);
	};

	const onTestRun = async () => {
		setTestLoading(true);
		try {
			const webFlowRun = await API.runWebFlowOnBrowserSession(webFlow.id, browserSession.public_id, {}, {});
			console.log(webFlowRun);
		} catch (error) {
			console.warn(error);
		}
		setTestLoading(false);
	};

	const onRemoveAction = (id) => {
		setWebFlow({ ...webFlow, actions: webFlow.actions.filter((a) => a.id !== id) });
		if (activeAction?.id === id) {
			setActiveAction(null);
		}
	};

	const onActionUpdate = (id, update) => {
		setWebFlow({ ...webFlow, actions: webFlow.actions.map((a) => a.id === id ? { ...a, ...update } : a) });
	};

	return (
		<Box flexGrow={1} display='flex' flexDirection='column'>
			<Box display='flex' justifyContent='space-between' alignItems='center' padding='0.75rem'>
				<Breadcrumbs sx={{ padding: 0 }}>
					<Link onClick={() => setPage(Pages.HOME)}>
						<Typography level='body-sm'>Flows</Typography>
					</Link>
					<Box>
						<Input
							variant='plain'
							size='sm'
							placeholder='New Web Flow'
							value={webFlow.name}
							startDecorator={<img src={webFlow.icon_url} width={14} height={14} />}
							onChange={(e) => setWebFlow({ ...webFlow, name: e.target.value })}
							sx={{
								fontSize: 'var(--Typography-fontSize, var(--joy-fontSize-sm, 0.875rem))',
								minHeight: '0',
								height: '23px',
								top: '1px',
								'--Input-radius': '0px',
								background: 'transparent',
								borderBottom: '2px solid',
								borderColor: 'neutral.outlinedBorder',
								padding: 0,
								'&:hover': {
									borderColor: 'neutral.outlinedHoverBorder',
								},
								'&::before': {
									border: '1px solid var(--Input-focusedHighlight)',
									transform: 'scaleX(0)',
									left: 0,
									right: 0,
									bottom: '-2px',
									top: 'unset',
									transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
									borderRadius: 0,
								},
								'&:focus-within::before': {
									transform: 'scaleX(1)',
								},
							}}
						/>
					</Box>
				</Breadcrumbs>
				<Box display='flex' gap={1}>
					<IconButton variant='shadowed' color='third' size='sm' onClick={onTestRun}>
						<PlayIcon fill='currentColor' width={12} height={12} />
					</IconButton>
				</Box>
			</Box>
			<Box p='0.75rem' pt='0.25rem' height='100%' display='flex' flexDirection='column' gap={1} onClick={() => {setActiveAction(null); }}>
				<Box component='form' height='100%' display='flex' flexDirection='column' gap={1} onSubmit={onSave}>
					<Box border='1px solid #E4E4E4' borderRadius={5} overflow='hidden'>
						<Input
							variant='plain'
							sx={{
								flexGrow: 1,
								paddingLeft: 0,
								border: 0,
								borderRadius: 0,
								'&:before': {
									boxShadow: 'none',
								},
							}}
							slotProps={{
								startDecorator: {
									sx: {
										margin: 0,
										padding: '2px 8px',
									},
								},
							}}
							startDecorator={<Chip size='sm' variant='soft' color='primary'>Start</Chip>}
							size='sm'
							value={webFlow.start_url}
							onChange={(e) => setWebFlow({ ...webFlow, start_url: e.target.value })}
						/>
					</Box>
					<Box display='flex' flexDirection='column' gap={1}>
						{webFlow.actions.map((action) => {
							return (
								<Box key={action.id} border='1px solid #E4E4E4' borderRadius={5} overflow='hidden'>
									<WebAction
										onSelected={(action) => setActiveAction(action)}
										onRemove={() => onRemoveAction(action.id)}
										activeId={activeAction?.id}
										action={action}
										onChange={(update) => onActionUpdate(action.id, update)}
									/>
								</Box>
							);
						})}
					</Box>
					<Box display='flex' justifyContent='flex-end' gap={1}>
						<Button type='submit' loading={testLoading} size='sm' color='secondary' variant='shadowed'>Save</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};


export default WebFlow;
