import styled from '@emotion/styled';
import { Box, Button, Divider, Typography } from '@mui/joy';
import React from 'react';
import { ReactComponent as CheckCircleIcon } from '@/public/icons/check-circle.svg';
import { ReactComponent as LogoIcon } from '@/public/icon.svg';


const PlanContainer = styled(Box)`
	background-color: #fafafa;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
  box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor);
	height: 100%;
`;

export interface PlanType {
	name: string;
	price: number;
	features: {
    numCustomAutomations: number;
    numRequests: number;
  },
  additionalFeatures: string[];
}

const Plan = ({ plan }: { plan: PlanType }) => {
	return (
		<PlanContainer boxShadow='sm'>
			<LogoIcon width='48px' height='48px' />
			<Typography marginTop={1} marginBottom={1} level='h3'>{plan.name}</Typography>
			<Divider />
			<Box marginTop={2}>
				{plan.price === null ? (
					<>
						<Typography level='h1' lineHeight='40px'>Contact Us</Typography>
						<Typography level='body-sm' color='neutral'>Get in touch with us</Typography>
					</>
				) : (
					<>
						<Typography level='h1' fontWeight={800} lineHeight='40px'>${plan.price}</Typography>
						<Typography level='body-sm' color='neutral'>per month</Typography>
					</>
				)}
			</Box>
			<Box display='flex' flexDirection='column' gap={1} marginY={2} width='100%' flexGrow={1}>
				<Box display='flex' alignItems='center' gap={1}>
					<CheckCircleIcon fill='#333333' width='14px' height='14px' />
					<Typography>{plan.features.numCustomAutomations} custom automations</Typography>
				</Box>
				<Box display='flex' alignItems='center' gap={1}>
					<CheckCircleIcon fill='#333333' width='14px' height='14px' />
					<Typography>{plan.features.numRequests} requests per month</Typography>
				</Box>
				{plan.additionalFeatures.map((feature) => (
					<Box display='flex' alignItems='center' gap={1} key={feature}>
						<CheckCircleIcon fill='#333333' width='14px' height='14px' />
						<Typography>{feature}</Typography>
					</Box>
				))}
			</Box>
			{plan.price === null ? (
				<Button fullWidth variant='shadowed' size='md' color='third'>Contact Us</Button>
			) : (
				<Button fullWidth variant='shadowed' size='md' color='secondary'>Get Started</Button>
			)}
		</PlanContainer>
	);
};

export default Plan;
