import React, { useContext } from 'react';
import Main from '@/src/components/Main';
import Header from '@/src/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton } from '@mui/joy';
import { Paper } from '@mui/material';
import { UserContext } from '../context/UserContext';
import DashboardContainer from '../components/DashboardContainer';

const SettingsPage: React.FC = () => {
	const { user, loaded } = useContext(UserContext);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(user?.plan?.api_key);
	};

	return (
		<DashboardContainer>
			<Typography level='h2'>Settings</Typography>
			<Typography level='body-md'>Manage your account settings and preferences.</Typography>

			<Box marginTop={3}>
				<Box display='flex' alignItems='center' gap={2}>
					<Box width='25%'>
						<Typography level='title-md'>API Key</Typography>
						<Typography level='body-sm'>Use your API key to access our API.</Typography>
					</Box>
					<Box flexGrow={1} display='flex'>
						{loaded && (
							<Input
								sx={{ width: '420px' }}
								onChange={() => {}}
								value={user?.plan?.api_key}
								endDecorator={<Button onClick={copyToClipboard} size='sm' variant='shadowed' color='secondary'>Copy</Button>}
							/>
						)}
					</Box>
				</Box>
			</Box>
		</DashboardContainer>
	);
};

export default SettingsPage;
