import Main from '@/src/components/Main';
import styled from '@emotion/styled';
import { useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as GoogleIcon } from '@/public/icons/google.svg';
import { HeaderLanding } from '../components/Header';
import Footer from '../components/Footer';
import { AspectRatio, Box, Button, ButtonGroup, Chip, Divider, Grid, Input, ToggleButtonGroup, Typography } from '@mui/joy';
import { ReactComponent as CodeIcon } from '@/public/icons/code.svg';
import { ReactComponent as ArrowUpRightIcon } from '@/public/icons/arrow-up-right.svg';
import { ReactComponent as ArrowRightIcon } from '@/public/icons/arrow-right.svg';
import { Helmet } from 'react-helmet';
import { ReactComponent as RocketIcon } from '@/public/icons/rocket.svg';
import { ReactComponent as AuthIcon } from '@/public/icons/authentication.svg';
import { ReactComponent as CursorIcon } from '@/public/icons/cursor.svg';
import { ReactComponent as APIIcon } from '@/public/icons/api.svg';
import { ReactComponent as GitHubIcon } from '@/public/icons/github.svg';
import Fade from 'react-reveal/Fade';


import API from '../api';
import { NavLink } from 'react-router-dom';
import { plans, PlansContainer } from './Pricing';
import Plan from './Pricing/Plan';


const HeroContainer = styled.div`
	padding-top: 10rem;
	padding-bottom: 6rem;
	padding-left: 2rem;
	padding-right: 2rem;
	position: relative;
	background-color: #ffffff;
	background-size: 42px 42px;
	background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
	@media (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}
`;

const HeroContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	align-items: center;
	z-index: 10;
	position: relative;
	gap: 1rem;
	max-width: 1000px;
	margin: 0 auto;
`;

const RadialGradient = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	height: 100%;
	mask-image: radial-gradient(ellipse at center,transparent 1%,#000 75%);
	background-color: white;
`;

const LogosContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 5rem;
	z-index: 10;
	position: relative;
`;

const LogosContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	filter: grayscale(100%);
`;

const LogoContainer = styled(Box)`
	background-color: #e3effb;
	color: #12467b;
	padding: 5px;
	border-radius: 5px;
	max-width: max-content;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 8px;
`;

const CodeExampleContainer = styled.div`
	background-color: #fff;
	padding: 1rem;
	border-radius: 8px;
	background: #282c34;
	// box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	// border: 1px solid #E4E4E4;
	margin-top: 1rem;
`;

const CodeExample = styled.div`

`;

const MacButton = styled.div<{color: string}>`
	width: 10px;
	height: 10px;
	border-radius: 10px;
	background-color: ${({ color }) => color};
`;

const Section = styled.section`
	display: flex;
	justify-content: center;
	padding: 1rem;
	background-color: white;
`;

const InfoContainer = styled(Box)`
	background-color: #fafafa;
	padding: 1rem;
	border-radius: 8px;
	border: 1px solid #E4E4E4;
	box-shadow: 0 2px inset var(--joy-palette-third-shadowHighColor);
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`;


const LandingPage: React.FC = () => {
	const [integrationSources, setIntegrationSources] = useState([]);
	const [filterType, setFilterType] = useState('all');
	const isMobile = useMediaQuery('(max-width: 992px)');

	// useEffect(() => {
	// 	API.getIntegrationSources().then((data) => {
	// 		console.log('data', data);
	// 		setIntegrationSources(data);
	// 	});
	// }, []);

	useEffect(() => {
		// check if query param has error or loginError
		const url = new URL(window.location.href);
		const error = url.searchParams.get('error');
		const loginError = url.searchParams.get('loginError');
		if (error || loginError) {
			// TODO: show snackbar with error
			console.error('Error:', error || loginError);
		}
	}
	, []);

	return (
		<div>
			<Helmet>
				<style>{'body { background-color: #ffffff !important; }'}</style>
			</Helmet>
			<HeaderLanding />
			<HeroContainer>
				<HeroContent>
					<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' marginBottom={6}>
						<Fade>
							{/* <Chip size='md' sx={{ mb: 0.5 }} startDecorator={<RocketIcon width={12} height={12} fill='currentColor' />} color='primary'>Early Access</Chip> */}
							<a href='https://github.com/shinpads/shinpads.dev' target='_blank' rel='noreferrer'>
								<Box display='flex' alignItems='center' border='1px solid #E4E4E4' borderRadius='4px' overflow='hidden' marginBottom={1}>
									<Box display='flex' gap={1} alignItems='center' paddingX={1} paddingY={.5} sx={{ backgroundColor: '#F7F7F7' }}>
										<GitHubIcon width={18} height={18} />
										<Typography level='title-sm'>Star</Typography>
									</Box>
									{/* <Divider orientation='vertical' sx={{ backgroundColor: '#666666' }} /> */}
									<Box display='flex' gap={1} alignItems='center' paddingX={1} paddingY={.5} sx={{ backgroundColor: '#ffffff' }}>
										<Typography level='body-xs'>open source</Typography>
									</Box>
								</Box>
							</a>
						</Fade>
						<Fade delay={50}>
							<Typography fontFamily='"Source Code Pro", sans-serif' textAlign='center' lineHeight={1.1} letterSpacing={-2.5} fontSize={58} level='h1'>Simple Browser Automation</Typography>
						</Fade>
						<Fade delay={100}>
							<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
							Standardized, structured interface for using anything on the web.
							</Typography> </Fade>
						<Fade delay={150}>
							<Box display='flex' gap={1} marginTop={4}>
								<a href='https://cal.com/robfarlow/shinpads.dev-intro' target='_blank' rel='noreferrer'>
									<Button size='md' color='third' variant='shadowed'>Book a Demo</Button>
								</a>
								<NavLink to='/login'>
									<Button size='md' color='secondary' variant='shadowed' endDecorator={<ArrowRightIcon width={12} height={12} fill='currentColor' />}>Get Started</Button>
								</NavLink>
							</Box>
						</Fade>
					</Box>
					<Fade delay={200}>
						<Box maxWidth='800px' width='100%' margin='0 auto'>
							<AspectRatio variant='plain'>
								<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' height='100%' border='1px solid #E4E4E4' borderRadius='8px' overflow='hidden' boxShadow='sm'>
									<Box width='100%' height='8%' bgcolor='#ababab' display='flex' alignItems='center' padding='0 8px'>
										<Box width='10px' height='10px' bgcolor='#FF4136' borderRadius='50%' marginRight='4px'></Box>
										<Box width='10px' height='10px' bgcolor='#FFDC00' borderRadius='50%' marginRight='4px'></Box>
										<Box width='10px' height='10px' bgcolor='#01FF70' borderRadius='50%'></Box>
									</Box>
									<Box width='100%' height='92%' bgcolor='#F7F7F7' display='flex' gap={2} padding={2}>
										<Box height='100%' width='20%' bgcolor='#E4E4E4' borderRadius='4px'>

										</Box>
										<Box display='flex' flexDirection='column' gap={2} flexGrow={1}>
											<Box width='100%' height='52px' bgcolor='#E4E4E4' borderRadius='4px' />
											<Box display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={2} margin={0} flexGrow={1} width='100%'>
												{Array.from({ length: 8 }).map((_, index) => (
													<Box display='flex' flexDirection='column' width='100%' height='100%' gap={.5} p={0} key={index} borderRadius='4px'>
														<Box position='relative' flexGrow={1} bgcolor='#E4E4E4' borderRadius='4px' border='1px dashed #1e88e5'>
															{index === 0 && <Chip sx={{ position: 'absolute', top: '-10px', left: '-26px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='sm' color='primary'>img_url</Chip>}
														</Box>
														<Box display='flex' flexDirection='column' gap={.5}>
															<Box position='relative' width='50%' height='8px' bgcolor='#E4E4E4' borderRadius='2px' border='1px dashed #1e88e5'>
																{index === 0 && <Chip sx={{ position: 'absolute', top: '-10px', left: '-26px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='sm' color='primary'>title</Chip>}
															</Box>
															<Box position='relative' display='flex' gap={.5} flexDirection='column' border='1px dashed #1e88e5'>
																{index === 0 && <Chip sx={{ position: 'absolute', top: '-4px', left: '-62px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='sm' color='primary'>description</Chip>}
																<Box width='100%' height='6px' bgcolor='#E4E4E4' borderRadius='2px' />
																<Box width='100%' height='6px' bgcolor='#E4E4E4' borderRadius='2px' />
															</Box>
															<Box position='relative' width='25%' height='12px' bgcolor='#E4E4E4' borderRadius='2px' border='1px dashed #1e88e5'>
																{index === 0 && <Chip sx={{ position: 'absolute', top: '-3px', right: '28px', zIndex: 10, fontSize: '10px', minHeight: 0 }} size='sm' color='primary'>add_to_cart_url</Chip>}
															</Box>
															{/* <Box width='100%' height='8px' bgcolor='#E4E4E4' borderRadius='4px' /> */}
														</Box>
													</Box>
												))}
											</Box>
										</Box>
									</Box>
								</Box>
							</AspectRatio>
						</Box>
					</Fade>
				</HeroContent>
				{/* <LogosContainer>
					<LogosContent>
						<AboardLogo height={28} />
						<BluBirdLogo height={28} />
					</LogosContent>
				</LogosContainer> */}
				<RadialGradient />
			</HeroContainer>
			<main style={{ padding: '2rem 0rem' }}>
				<Section style={{ paddingBottom: '2rem' }}>
					<Box display='flex' flexDirection='column' gap={2} width='1000px' maxWidth='100%'>
						<Fade>
							<Box mb={2}>
								<Typography fontFamily='"Source Code Pro", sans-serif' textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>Everything you need</Typography>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
								Everything you need to create powerful browser automations.
								</Typography>
							</Box>
						</Fade>
						<Fade>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer flexBasis='61.8%'>
									<LogoContainer>
										<CodeIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Run as an API</Typography>
									<Typography level='body-sm'>Query workflows as an API from our library or your custom created workflows.</Typography>
									<CodeExampleContainer>
										<CodeExample>
											<Box display='flex' gap={1} border='1px solid #666666' borderRadius='4px' padding={1} marginBottom={1} alignItems='center'>
												<Chip size='sm' color='primary' variant='solid'>GET</Chip>
												<Divider orientation='vertical' sx={{ backgroundColor: '#666666' }} />
												<Box>
													<Typography textColor='common.white' level='body-sm'>api.shinpads.dev/acme/get-listings</Typography>
												</Box>
											</Box>
											<Typography level='body-sm' className='language-python' style={{ whiteSpace: 'normal', overflowWrap: 'anywhere', color: '#ABB2BF', fontSize: '14px' }}>
												<span>{'{'}</span><br/>
												<div style={{ marginLeft: '10px' }}>
													<span style={{ color: '#E06C75' }}>&quot;success&quot;</span>: <span style={{ color: '#E5C07B' }}>true</span>,<br/>
													<span style={{ color: '#E06C75' }}>&quot;listings&quot;: </span><span>{'['}</span><br/>
													<div style={{ marginLeft: '10px' }}>
														<span>{'{'}</span><br/>
														<div style={{ marginLeft: '10px' }}>
															<span style={{ color: '#ABB2BF' }}>
																<span style={{ color: '#E06C75' }}>&quot;name&quot;</span>: <span style={{ color: '#98C379' }}>&quot;Red Sunglasses&quot;</span>,<br/>
																<span style={{ color: '#E06C75' }}>&quot;price&quot;</span>: <span style={{ color: '#E5C07B' }}>29.99</span>,<br/>
																<span style={{ color: '#E06C75' }}>&quot;description&quot;</span>: <span style={{ color: '#98C379' }}>&quot;A pair of red sunglasses&quot;</span>,<br/>
															</span>
														</div>
														<span>{'}'}</span>,<br/>
													</div>
													<span>{']'}</span>
												</div>
												<span>{'}'}</span>
											</Typography>
										</CodeExample>
									</CodeExampleContainer>
								</InfoContainer>
								<InfoContainer flexBasis='38.2%'>
									<LogoContainer>
										<AuthIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Authentication handled</Typography>
									<Typography level='body-sm'>Don&apos;t worry about authentication</Typography>
									<Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center' marginY={2}>
										<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' borderRadius='8px' padding={2} paddingY={4} sx={{ backgroundColor: '#fff', maxWidth: '300px', width: '100%' }} boxShadow='rgba(36, 42, 66, 0.08) 0px 8px 20px -6px, rgba(36, 42, 66, 0.06) 0px 1px 4px -1px, rgba(36, 42, 66, 0.06) 0px 0px 2px 0px'>
											<Typography textAlign='left' width='100%' level='title-lg' marginBottom={2}>Login</Typography>
											<Box display='flex' flexDirection='column' gap={2} width='100%'>
												<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
												<Box width='100%' height='28px' sx={{ backgroundColor: '#eef0f1', borderRadius: '4px' }} />
												<Button disabled sx={{ marginTop: 1 }} variant='shadowed' color='secondary' size='sm' fullWidth>Login</Button>
											</Box>
										</Box>
									</Box>
								</InfoContainer>
							</Box>
							<Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
								<InfoContainer sx={{ flexBasis: '50%' }}>
									<LogoContainer>
										<CursorIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Intelligent Automation</Typography>
									<Typography level='body-sm'>Handle arbitrary tasks, and deal with unpredictable inputs</Typography>
								</InfoContainer>
								<InfoContainer sx={{ flexBasis: '50%' }}>
									<LogoContainer>
										<APIIcon width={18} height={18} fill='currentColor' />
									</LogoContainer>
									<Typography level='h3'>Library of pre-built flows</Typography>
									<Typography level='body-sm'>Simply query them as an API, and handle the responses.</Typography>
								</InfoContainer>
							</Box>
						</Fade>
					</Box>
				</Section>
				<Section style={{ marginTop: '6rem', backgroundImage: 'linear-gradient(to right, #d9d9d9 1px, transparent 1px), linear-gradient(to bottom, #d9d9d9 1px, transparent 1px)', backgroundSize: '42px 42px', position: 'relative' }}>
					<Box display='flex' flexDirection='column' zIndex={10} width='1000px' maxWidth='100%'>
						<Box mb={2}>
							<Fade>
								<Typography fontFamily='"Source Code Pro", sans-serif' textAlign='center' lineHeight={1.1} letterSpacing={-2.2} fontSize={48} level='h1'>Pricing</Typography>
							</Fade>
							<Fade delay={50}>
								<Typography textAlign='center' color='neutral' level='body-md' marginTop={1}>
									Choose a plan that fits your needs.
								</Typography>
							</Fade>
						</Box>
						<PlansContainer>
							{plans.map((plan, index) => (
								<Fade key={plan.name} delay={50 + (75 * index)}>
									<Plan plan={plan} />
								</Fade>
							))}
						</PlansContainer>
					</Box>
					<RadialGradient />
				</Section>
			</main>
			<Footer />
		</div>
	); };

export default LandingPage;
