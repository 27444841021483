import React, { useContext, useEffect, useState } from 'react';
import Main from '@/src/components/Main';
import Header from '@/src/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup } from '@mui/joy';
import { Paper } from '@mui/material';
import moment from 'moment'; import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import { UserContext } from '@/src/context/UserContext';
import DashboardContainer from '@/src/components/DashboardContainer';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? '#f0f0f0' : 'transparent'};
`;

const DashboardPage: React.FC = () => {
	const { user, loaded } = useContext(UserContext);
	const [usage, setUsage] = useState([]);
	const [selectedUsage, setSelectedUsage] = useState(null);
	const [view, setView] = useState('response');


	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Dashboard</Typography>
		</DashboardContainer>
	);
};

export default DashboardPage;
