import React, { useContext } from 'react';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { UserContext } from './context/UserContext';
import Pricing from './pages/Pricing';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import API from './api';
import { Provider, ErrorBoundary } from '@rollbar/react';
import Rollbar from 'rollbar';
import { Typography } from '@mui/joy';
import styled from '@emotion/styled';
import ChatPage from './pages/Chat';
import TestIntegrationsPage from './pages/TestIntegrations';
import IntegrationsPage from './pages/Integrations';
import AppsPage from './pages/Apps';
import SettingsPage from './pages/Settings';
import LandingPage from './pages/LandingPage';
import IntegrationPage from './pages/Integration';
import UsagePage from './pages/Usage';
import AdminPage from './pages/Admin';
import DashboardPage from './pages/Dashboard';
import BuilderPage from './pages/Builder';
import SessionsPage from './pages/SessionsPage';
import WebFlowRunsPage from './pages/WebFlowRunsPage';
import WebFlowsPage from './pages/WebFlowsPage';


const ViewAsContainer = styled.div`
	position: fixed;
	bottom: 1rem;
	left: 1rem;
	border: 1px dashed black;
	background: white;
	border-radius: 8px;
	padding: 1rem;
	background-color: white;
	z-index: 1000;
	box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
`;


const App = () => {
	const user = useContext(UserContext);
	const [searchParams, _] = useSearchParams();

	const userWasLoaded = localStorage.getItem('user_loaded') === 'true' || searchParams.get('login') === 'true';

	const rollbarConfig: Rollbar.Configuration = {
		accessToken: 'eefc00eba2c64d4a9dee975af8a7bca9',
		captureUncaught: true,
		captureUnhandledRejections: true,
		environment: ENVIRONMENT,
		payload: {
			person: {
				id: user?.user?.id,
				username: user?.user?.email,
				email: user?.user?.email,
			},
		},
	};

	return (
		<Provider config={rollbarConfig}>
			<ErrorBoundary>
				{user.isViewAs && (
					<ViewAsContainer>
						<Typography color='danger' level='title-lg'>Viewing as</Typography>
						<Typography level='body-sm'> {user.user.email}</Typography>
						<a onClick={API.clearViewAs} style={{ cursor: 'pointer' }}>
							<Typography color='primary' level='body-xs'>Clear</Typography>
						</a>
						{/* You are viewing as {user.user.email} <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={API.clearViewAs}>Clear</a> */}
					</ViewAsContainer>
				)}
				<Routes>
					{(!user.loaded && userWasLoaded) || (user.loaded && user?.user?.email) ? (
						<>
							<Route path='/usage' element={<UsagePage />} />
							<Route path='/web-flow-runs' element={<WebFlowRunsPage />} />
							<Route path='/chat' element={<ChatPage />} />
							<Route path='/web-flows' element={<WebFlowsPage />} />
							<Route path='/integrations' element={<IntegrationsPage />} />
							<Route path='/sessions' element={<SessionsPage />} />
							<Route path='/test' element={<TestIntegrationsPage />} />
							<Route path='/apps' element={<AppsPage />} />
							<Route path='/settings' element={<SettingsPage />} />
							<Route path='/admin' element={<AdminPage />} />
							<Route path='/builder' element={<BuilderPage />} />
							<Route path='/' element={<DashboardPage />} />
						</>
					) : (
						<Route path='/' element={<LandingPage />} />
					)}

					<Route path='/landing' element={<LandingPage />} />
					<Route path='/integration/:name' element={<IntegrationPage />} />
					<Route path='/login' element={<LoginPage />} />
					<Route path='/pricing' element={<Pricing />} />
					<Route path='/privacy' element={<PrivacyPolicyPage />} />
					<Route path='*' element={<Navigate to='/' />} />
				</Routes>
			</ErrorBoundary>
		</Provider>
	);
};

export default App;
