import React, { createContext, useEffect, useState } from 'react';
import API from '../api';
import moment from 'moment';
import { usePostHog } from 'posthog-js/react';

// Create the UserContext
export const UserContext = createContext<UserContextType>(null);

interface UserContextType {
	loaded: boolean;
	user: any;
	isDebugMode?: boolean;
	isViewAs?: boolean;
	isPlanActive?: boolean;
	numberOfListenersUsed?: number;
	numberOfPostsThisPeriod?: number;
	setIsDebugMode?: (isDebugMode: boolean) => void;
	listeners?: any;
	mediaCountForListener?: any;
}

// Create the UserProvider component
export const UserProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<UserContextType>({
		loaded: false,
		user: null,
	});
	const [isDebugMode, _setIsDebugMode] = useState(localStorage.getItem('debug_mode') === 'true');
	const posthog = usePostHog();

	useEffect(() => {
		if (isDebugMode) {
			localStorage.setItem('debug_mode', 'true');
		} else {
			localStorage.removeItem('debug_mode');
		}
	}, [isDebugMode]);


	const setIsDebugMode = (isDebugMode: boolean) => {
		_setIsDebugMode(isDebugMode);
		setUser((prev) => ({
			...prev,
			isDebugMode,
		}));
	};

	useEffect(() => {
		API.getUserFromSession().then((userData) => {
			console.log(userData);
			if (userData?.email) {
				localStorage.setItem('user_loaded', 'true');
			} else {
				localStorage.removeItem('user_loaded');
			}
			setUser({
				loaded: true,
				user: userData,
				isDebugMode,
				isViewAs: document.cookie.includes('view-as'),
			});
			posthog.identify(
				userData?.public_id,
				{
					email: userData?.email,
					name: userData?.name,
				}
			);
		});
	}, []);

	return (
		<UserContext.Provider value={{ ...user, setIsDebugMode }}>
			{children}
		</UserContext.Provider>
	);
};
