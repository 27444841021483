import Main from '@/src/components/Main';
import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { Box, Button, Divider, Grid, Input, Switch, Typography } from '@mui/joy';
import API from '@/src/api';
import ConnectIntegrationModal from './ConnectIntegrationModal';


const IntegrationsContainer = styled(Grid)`
`;
const IntegrationsPage: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const [integrationSources, setIntegrationSources] = useState([]);
	const [integrations, setIntegrations] = useState([]);
	const [connectIntegrationModalOpen, setConnectIntegrationModalOpen] = useState(null);

	useEffect(() => {
		setLoading(true);
		Promise.all([
			API.getIntegrationSources().then((data) => {
				data.sort((a, b) => b.methods?.length - a.methods?.length);
				setIntegrationSources(data);
			}),
			API.getIntegrations().then((data) => {
				setIntegrations(data);
			})
		]).then(() => {
			setLoading(false);
		});

	}, []);

	return (
		<div>
			<ConnectIntegrationModal
				key={connectIntegrationModalOpen?.id}
				open={Boolean(connectIntegrationModalOpen)}
				onClose={() => setConnectIntegrationModalOpen(null)}
				integrationSource={connectIntegrationModalOpen}
				integrations={integrations.filter((integration) => integration.integration_source_id === connectIntegrationModalOpen?.id)}
			/>
			<Main style={{ minHeight: '90vh' }}>
				<Header />
				<Typography level='h2'>Integrations</Typography>
				<Typography level='body-md'>Connect your favorite services to get started.</Typography>
				<Box display='flex' justifyContent='space-between' marginTop={2}>
					<Box display='flex' gap={1}>
						<Button size='sm' color='secondary' variant='solid'>All</Button>
						<Button size='sm' color='neutral' variant='outlined'>Connected</Button>
					</Box>
					<Input placeholder='Search' sx={{ minWidth: 300 }} />
				</Box>

				<IntegrationsContainer marginTop={3} container spacing={2}>
					{integrationSources.map((source) => {
						const connectedIntegrations = integrations.filter((integration) => integration.integration_source_id === source.id);
						return (
							<Grid key={source.id} xs={12} sm={6} md={3} lg={3} xl={3}>
								<Paper sx={{ padding: 0 }}>
									<Box padding={2}>
										<img src={source.icon_url} alt={source.name} width={32} height={32} />
										<Typography level='title-lg'>{source.name}</Typography>
										<Typography level='body-sm'>{source.description}</Typography>
									</Box>
									<Divider />
									<Box paddingY={1} paddingX={2} display='flex' justifyContent='space-between'>
										{connectedIntegrations.length === 0 ? (
											<Button onClick={() => setConnectIntegrationModalOpen(source)} size='sm' variant='outlined'>Connect</Button>
										) : (
											<Button size='sm' variant='soft' onClick={() => setConnectIntegrationModalOpen(source)}>Manage</Button>
										)}
										<Switch checked={connectedIntegrations.length > 0} color={connectedIntegrations.length > 0 ? 'primary' : 'neutral'} />
									</Box>
								</Paper>
							</Grid>
						);
					})}
				</IntegrationsContainer>
			</Main>
		</div>
	);
};

export default IntegrationsPage;
