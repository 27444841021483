import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, Input, List, ListItemDecorator, ListItem, ListItemButton, ListItemContent, Typography, Button } from '@mui/joy';
import API from '@/src/api';
import { ReactComponent as ChevronRightIcon } from '@/public/icons/chevron-right.svg';
import styled from '@emotion/styled';
import { ReactComponent as LogoIcon } from '@/public/icon.svg';
import { ReactComponent as LogoRawIcon } from '@/public/icon-raw.svg';
import { BuilderContextProvider, BuilderContext, Pages } from './BuilderContext';
import ViewIntegration from './sections/ViewIntegration';
import { NavLink } from 'react-router-dom';
import WebFlow from './sections/WebFlow';
import moment from 'moment';

const Root = styled(Box)`
  width: 360px;
  height: 100%;
  background-color: #ffff;
  border-radius: 5px;
  flex-shrink: 0;
  overflow-y: auto;
  // border-right: 1px solid #e4e4e4;
  border: 1px solid #e4e4e4;
  position: relative;
	display: flex;
	flex-direction: column;
`;

const BorderedImage = styled.img`
	border: 1px solid #e4e4e4;
	border-radius: 5px;
	padding: 2px;
	box-sizing: border-box;
`;

const Header = styled(Box)`
	background-color: rgba(240, 240, 240, 0.70);
	backdrop-filter: blur(8px);
	border-bottom: 1px solid #e4e4e4;
  display: flex;
  height: 58px;
  align-items: center;
  gap: 8px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
`;


const ShinpadsBuilder = () => {
	const { page, selectedWebFlow } = useContext(BuilderContext);
	const ref = useRef(null);

	useEffect(() => {
		// scroll to top
		ref.current.scrollTo({ top: 0 });
	}, [page]);

	return (
		<Root boxShadow='md' ref={ref}>
			<Header p={2}>
				<NavLink style={{ display: 'flex' }} to='/'>
					<LogoIcon style={{ borderRadius: '4px' }} width={28} height={28} />
				</NavLink>
				<NavLink to='/'>
					<Typography fontFamily='"Source Code Pro", monospace' fontSize='18px' fontWeight='bold' level='title-md'>shinpads.dev</Typography>
				</NavLink>
			</Header>
			{page === Pages.HOME && <Home />}
			{page === Pages.INTEGRATION && <ViewIntegration />}
			{page === Pages.WEB_FLOW && <WebFlow webFlow={selectedWebFlow} />}
		</Root>
	);
};

const Home = () => {
	const { setPage, webFlows, setSelectedWebFlow } = useContext(BuilderContext);
	const [webFlowSearch, setWebFlowSearch] = useState('');

	const onSelectWebFlow = (webFlow) => {
		setSelectedWebFlow(webFlow);
		setPage(Pages.WEB_FLOW);
	};


	return (
		<Box p={2}>
			<Input placeholder='Search or create' value={webFlowSearch} onChange={(e) => setWebFlowSearch(e.target.value)} />
			<List>
				{webFlows.filter((wf) => wf.name.toLowerCase().includes(webFlowSearch.toLowerCase())).map((wf) => (
					<ListItem key={wf.id}>
						<ListItemButton onClick={() => onSelectWebFlow(wf)}>
							{/* <ListItemDecorator>
								{is.icon_url
									&& <BorderedImage src={is.icon_url} alt={is.name} width={32} height={32} />
								}
							</ListItemDecorator> */}
							<ListItemContent>
								<Typography level='title-md'>{wf.name}</Typography>
								<Typography level='body-xs'>updated {moment(wf.updated_at).fromNow()}</Typography>
							</ListItemContent>
							<ChevronRightIcon width={12} height={12} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
			{webFlowSearch.length > 0 && (
				<Button fullWidth variant='soft' color='neutral' onClick={(e) => {}}>Create {webFlowSearch}</Button>
			)}
		</Box>
	);
};


export default ShinpadsBuilder;
