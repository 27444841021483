import axios from 'axios';

class API {
	static async getUserFromSession() {
		const user = await axios.get('/api/user/from-session');

		return user.data;
	}

	static async viewAsUser(email: string) {
		const res = await axios.post('/api/admin/view-as', { email });
		const { data } = res;
		if (!data?.id) {
			console.error('User not found');
			return;
		}
		document.cookie = `view-as=${data.publicId}`;
		window.location.href = '/';
	}

	static async clearViewAs() {
		document.cookie = 'view-as=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		window.location.href = '/';
	}

	static async submitContactForm(message: string) {
		await axios.post('/api/user/contact-form', { message });
	}

	static async logout() {
		await axios.post('/api/user/logout');
	}

	static async createChatMessage(message: string) {
		const res = await axios.post('/api/chat/message', { message });
		return res.data;
	}

	static async getIntegrationSources() {
		const res = await axios.get('/api/integration-sources');
		return res.data;
	}

	static async createIntegration(integrationSourceId: string, data: any) {
		const res = await axios.post('/api/integrations', { integration_source_id: integrationSourceId, data });
		return res.data;
	}

	static async getIntegrations() {
		const res = await axios.get('/api/integrations');
		return res.data;
	}

	static async getIntegrationByName(name: string) {
		const res = await axios.get(`/api/integrations/${name}`);
		return res.data;
	}

	static async getUsage() {
		const res = await axios.get('/api/usage');
		return res.data;
	}

	static async getBrowserSessions() {
		const res = await axios.get('/api/browser/sessions');
		return res.data;
	}

	static async callIntegrationMethod(integrationMethodId: number, userIdentifier: string, params: any) {
		const res = await axios.post(`/api/integrations/method/${integrationMethodId}/call`, { user_identifier: userIdentifier, params });
		return res.data;
	}

	static async getBuilderSession() {
		const res = await axios.get('/api/browser/session/builder');
		return res.data;
	}

	static async createWebFlow(webFlow: any) {
		const res = await axios.post('/api/web-flows', webFlow);
		return res.data;
	}

	static async getWebFlows() {
		const res = await axios.get('/api/web-flows');
		return res.data;
	}

	static async runWebFlowOnBrowserSession(webFlowId: number, browserSessionId: string, parameters: any, options: any) {
		const res = await axios.post(`/api/web-flows/${webFlowId}/run-on-browser-session/${browserSessionId}`, { parameters, options });
		return res.data;
	}

	static async getWebFlowRuns() {
		const res = await axios.get('/api/web-flow-runs');
		return res.data;
	}
}

export default API;
