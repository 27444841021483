import React, { useContext, useEffect, useState } from 'react';
import Main from '@/src/components/Main';
import Header from '@/src/components/Header';
import { Typography, Box, Grid, Input, Button, Link, Tooltip, Modal, ModalDialog, ModalClose, Textarea } from '@mui/joy';
import API from '@/src/api';
import axios from 'axios';
import { UserContext } from '@/src/context/UserContext';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const AdminPage: React.FC = () => {
	const [integrationSources, setIntegrationSources] = useState([]);
	const { user } = useContext(UserContext);
	const [modalOpen, setModalOpen] = useState(null);
	const [params, setParams] = useState('{}');

	useEffect(() => {
		API.getIntegrationSources().then((data) => {
			console.log(data);
			setIntegrationSources(data);
		});
	}, []);

	const testMethod = async (integrationMethod) => {
		const response = await API.callIntegrationMethod(integrationMethod.id, user.id, JSON.parse(params));
		console.log(response);
	};

	const getDefaultParamByType = (type) => {
		switch (type) {
		case 'string':
			return 'test';
		case 'number':
			return 1;
		case 'boolean':
			return true;
		case 'array':
			return [];
		case 'object':
			return { };
		default:
			return null;
		}
	};

	const openTestMethodModal = (integrationMethod) => {
		const defaultParams = {};
		integrationMethod.parameters.forEach((param) => {
			defaultParams[param.name] = getDefaultParamByType(param.type);
		});
		setParams(JSON.stringify(defaultParams, null, 2));
		setModalOpen(integrationMethod);
	};

	return (
		<div>
			<Modal open={modalOpen} onClose={() => setModalOpen(null)}>
				<ModalDialog sx={{ minWidth: '450px', overflowY: 'auto' }}>
					<ModalClose />
					<Typography level='h4'>Test {modalOpen?.name}</Typography>
					<Box display='flex' flexDirection='column'>
						{modalOpen?.parameters.map((param) => (
							<Box key={param.name}>
								<Typography level='body-sm'><b>{param.name}</b>: {param.description}</Typography>
							</Box>
						))}
					</Box>
					<Textarea
						value={params}
						onChange={(e) => setParams(e.target.value)}
						minRows={4}
						size='sm'
						sx={{ width: '100%', marginY: 2, '& textarea': { overflowY: 'auto' } }}
					/>
					<Button
						onClick={() => testMethod(modalOpen)}
						sx={{ alignSelf: 'flex-end' }}
					>
						Test
					</Button>
				</ModalDialog>
			</Modal>
			<Main style={{ minHeight: '90vh' }}>
				<Header />
				<Typography level='h2'>Admin Dashboard</Typography>
				<Typography level='body-md'>Manage administrative tasks and settings.</Typography>

				<Box marginTop={3}>
					<Typography level='h3'>Integration Sources</Typography>
					<Typography level='body-md'>Manage integration sources.</Typography>
					<Box marginTop={3} display='flex' flexDirection='column' gap={1}>
						{integrationSources.map((source) => {
							return (
								<Box key={source.id}>
									<Box display='flex' alignItems='center' gap={1}>
										<img width={18} src={source.icon_url} alt={source.name} />
										<Typography level='title-md'>{source.name}</Typography>
									</Box>
									{source.api_components?.securitySchemes?.map((scheme) => (
										<Tooltip
											key={scheme.id}
											title={<SyntaxHighlighter wrapLongLines language='json' style={a11yLight}>{JSON.stringify(scheme, null, 2)}</SyntaxHighlighter>}
										>
											<Typography marginLeft={2} key={scheme.id} level='title-sm'>API Authentication</Typography>
										</Tooltip>
									))}
									{source.methods.map((method) => (
										<Box key={method.id} display='flex' alignItems='center' marginTop={1} gap={1} marginLeft={2}>
											<Typography level='title-sm'>{method.name}</Typography>
											<Link onClick={() => openTestMethodModal(method)}>
												<Typography color='primary' level='title-sm'>
													Test
												</Typography>
											</Link>
										</Box>
									))}
								</Box>
							);
						})}
					</Box>
				</Box>
			</Main>
		</div>
	);
};

export default AdminPage;
