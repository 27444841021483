import React, { useContext, useEffect, useState } from 'react';
import Main from '@/src/components/Main';
import Header from '@/src/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup } from '@mui/joy';
import { Paper } from '@mui/material';
import { UserContext } from '../context/UserContext';
import API from '../api';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? '#f0f0f0' : 'transparent'};
`;

const formatDuration = (duration: moment.Duration) => {
	const hours = duration.hours();
	const minutes = duration.minutes();
	const seconds = duration.seconds();

	return `${hours ? `${hours}h ` : ''}${minutes ? `${minutes}m ` : ''}${seconds ? `${seconds}s` : ''}`;
};

const SessionsPage: React.FC = () => {
	const { user, loaded } = useContext(UserContext);
	const [sessions, setSessions] = useState([]);
	const [selectedSession, setSelectedSession] = useState(null);
	const [view, setView] = useState('response');

	useEffect(() => {
		API.getBrowserSessions().then((data) => {
			console.log(data);
			setSessions(data.sessions);
			setSelectedSession(data.sessions[0]);
		});
	}, []);

	useEffect(() => {
		if (selectedSession) {
			setView('response');
		}
	}, [selectedSession]);

	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Browser Sessions</Typography>
			<Grid container spacing={2}>
				<Grid xs={12} md={7}>
					<Paper style={{ padding: 0 }}>
						<table>
							<thead>
								<tr>
									{/* <th><Typography level='title-sm' color='neutral'>Source</Typography></th> */}
									<th><Typography level='title-sm' color='neutral'>Status</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Started At</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Duration</Typography></th>
								</tr>
							</thead>
							<tbody>
								{sessions.map((s) => (
									<UsageRow active={selectedSession?.id === s.id} key={s.id} onClick={() => setSelectedSession(s)}>
										{/* <td>
											<Box display='flex' alignItems='center' gap={1}>
												<img width={16} src={s.integration_source_method?.integration_source?.icon_url} alt={s.integration_source_method?.integration_source?.name} />
												<Typography level='title-sm'>{s.integration_source_method?.integration_source?.name}</Typography>
											</Box>
										</td> */}
										<td>
											<Chip size='sm' color={s.is_active ? 'primary' : 'warning'}>{s.is_active ? 'Active' : 'Ended'}</Chip>
										</td>
										<td>
											<Typography level='body-sm'>{moment(s.active_at).format('YYYY-MM-DD HH:mm:ss')}</Typography>
										</td>
										<td>
											{s.ended_at ? (
												<Typography level='body-sm'>{formatDuration(moment.duration(moment(s.ended_at).diff(moment(s.active_at))))}</Typography>
											) : (
												<Typography level='body-sm'>-</Typography>
											)}
										</td>
									</UsageRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={5}>
					<Paper style={{ padding: 0 }}>
						<Section>
							<Typography level='title-md'>{selectedSession?.public_id}</Typography>
						</Section>
						<Divider/>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Type</Typography>
								<Typography level='body-sm'>{selectedSession?.is_builder_session ? 'Builder' : 'Worker'}</Typography>
								<Typography color='neutral' level='title-sm'>Status</Typography>
								<Chip size='sm' color={selectedSession?.is_active ? 'primary' : 'warning'}>{selectedSession?.is_active ? 'Active' : 'Ended'}</Chip>
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Started At</Typography>
								{selectedSession?.active_at ? (
									<Typography level='body-sm'>{moment(selectedSession?.active_at).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm' whiteSpace='nowrap'>Ended At</Typography>
								{selectedSession?.ended_at ? (
									<Typography level='body-sm'>{moment(selectedSession?.ended_at).format('YYYY-MM-DD HH:mm:ss')}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
								<Typography color='neutral' level='title-sm'>Duration</Typography>
								{selectedSession?.active_at && selectedSession?.ended_at ? (
									<Typography level='body-sm'>{formatDuration(moment.duration(moment(selectedSession?.ended_at).diff(moment(selectedSession?.active_at))))}</Typography>
								) : (
									<Typography level='body-sm'>-</Typography>
								)}
							</Box>
						</Section>
						<Divider/>
						<Section>
							<Typography level='title-md'>Logs</Typography>
							<SyntaxHighlighter
								language='json'
								style={a11yLight}
								customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
								showLineNumbers
								wrapLongLines
							>
								{JSON.stringify(selectedSession?.response, null, 2)}
							</SyntaxHighlighter>
						</Section>
					</Paper>
				</Grid>
			</Grid>
		</DashboardContainer>
	);
};

export default SessionsPage;
