import React from 'react';
import Header, { HeaderLanding } from '../components/Header';
import Footer from '../components/Footer';
import Main from '../components/Main';
import { Typography } from '@mui/joy';

const PrivacyPolicyPage: React.FC = () => {
	return (
		<div>
			<Main style={{ minHeight: '90vh', paddingTop: '6rem' }}>
				<HeaderLanding />
				<Typography level='h1' style={{ marginBottom: '20px' }}>Privacy Policy</Typography>

				<Typography level='body-md'>
					This is the privacy policy of our website. We are committed to protecting your privacy and ensuring the security of your personal information. This policy explains how we collect, use, and safeguard your personal data when you visit our website. For more information contact us at rob@shinpads.dev
				</Typography>
				<br />
				<Typography level='body-md'>
					<b>1. Information We Collect:</b> We may collect personal information such as your name, email address, and contact details when you fill out a form on our website or interact with our services.
				</Typography>
				<br />
				<Typography level='body-md'>
					<b>2. How We Use Your Information:</b> We use the information we collect to provide and improve our services, communicate with you, and personalize your experience on our website.
				</Typography>
				<br />
				<Typography level='body-md'>
					<b>3. Data Security:</b> We take appropriate measures to protect your personal information from unauthorized access, alteration, or disclosure.
				</Typography>
				<br />
				<Typography level='body-md'>
					<b>4. Third-Party Links:</b> Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites.
				</Typography>
				<br />
				<Typography level='body-md'>
					<b>5. Changes to This Policy:</b> We may update this privacy policy from time to time. Any changes will be posted on this page.
				</Typography>
			</Main>
			<Footer />
		</div>
	);
};

export default PrivacyPolicyPage;
