import React, { createContext, useEffect, useState } from 'react';
import API from '../../api';

export const BuilderContext = createContext<BuilderContextType>(null);

interface BuilderContextType {
	integrationSources: any[];
	setIntegrationSources: (integrationSources: any[]) => void;
	selectedIntegrationSource: any;
	setSelectedIntegrationSource: (integrationSource: any) => void;
	webFlows: any[];
	setWebFlows: (webFlows: any[]) => void;
	selectedWebFlow: any;
	setSelectedWebFlow: (webFlow: any) => void;
	page: string;
	setPage: (page: string) => void;
	activeAction: any;
	setActiveAction: (action: any) => void;
	frameUrl: string;
	setFrameUrl: (url: string) => void;
	iconUrl: string | null;
	setIconUrl: (url: string | null) => void;
	selectMode: string | null;
	setSelectMode: (selectMode: string | null) => void;
	browserSession: any;
	setBrowserSession: (browserSession: any) => void;
}

export const Pages = {
	HOME: 'home',
	INTEGRATION: 'integration',

	ADD_INTEGRATION: 'add_integration',
	ADD_ENDPOINTS: 'add_endpoints',
	SELECT_ENDPOINT_DATA: 'select_endpoint_data',
	WEB_FLOW: 'web_flow',
};

export const SELECT_MODE = {
	UNIQUE: 'unique',
	ALL: 'all',
};

export const BuilderContextProvider = ({ children }: { children: React.ReactNode }) => {
	const [integrationSources, setIntegrationSources] = useState([]);
	const [selectedIntegrationSource, setSelectedIntegrationSource] = useState(null);
	const [webFlows, setWebFlows] = useState([]);
	const [selectedWebFlow, setSelectedWebFlow] = useState(null);
	const [page, setPage] = useState(Pages.WEB_FLOW);
	const [activeAction, setActiveAction] = useState(null);
	const [frameUrl, setFrameUrl] = useState('https://google.com');
	const [iconUrl, setIconUrl] = useState(null);
	const [selectMode, setSelectMode] = useState(null);
	const [browserSession, setBrowserSession] = useState(null);

	useEffect(() => {
		API.getWebFlows().then((res) => {
			console.log(res);
			setWebFlows(res);
		});
	}, []);

	useEffect(() => {
		if (selectedIntegrationSource) {
			setSelectedIntegrationSource(integrationSources.find(r => r.id === selectedIntegrationSource?.id));
		}
	}, [integrationSources]);

	return (
		<BuilderContext.Provider
			value={{
				integrationSources,
				setIntegrationSources,
				selectedIntegrationSource,
				setSelectedIntegrationSource,
				webFlows,
				setWebFlows,
				selectedWebFlow,
				setSelectedWebFlow,
				page,
				setPage,
				activeAction,
				setActiveAction,
				frameUrl,
				setFrameUrl,
				selectMode,
				setSelectMode,
				browserSession,
				setBrowserSession,
				iconUrl,
				setIconUrl,
			}}
		>
			{children}
		</BuilderContext.Provider>
	);
};
