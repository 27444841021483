import React, { useContext, useEffect, useState } from 'react';
import Main from '@/src/components/Main';
import Header from '@/src/components/Header';
import { Typography, Box, Grid, Input, Button, Skeleton, Table, Chip, Divider, ToggleButtonGroup } from '@mui/joy';
import { Paper } from '@mui/material';
import { UserContext } from '../context/UserContext';
import API from '../api';
import moment from 'moment';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styled from '@emotion/styled';
import DashboardContainer from '../components/DashboardContainer';

const Section = styled(Box)`
  padding: 1rem;
`;

const UsageRow = styled.tr<{ active: boolean }>`
  cursor: pointer;
  background-color: ${({ active }) => active ? '#f0f0f0' : 'transparent'};
`;

const WebFlowStateToChipColor = {
	completed: 'success',
	pending: 'warning',
	running: 'primary',
	failed: 'danger',
};

const WebFlowsPage: React.FC = () => {
	const { user, loaded } = useContext(UserContext);
	const [webFlows, setWebFlows] = useState([]);
	const [selectedWebFlow, setSelectedWebFlow] = useState(null);
	const [view, setView] = useState('response');

	useEffect(() => {
		API.getWebFlows().then((data) => {
			console.log(data);
			setWebFlows(data);
			setSelectedWebFlow(data[0]);
		});
	}, []);

	useEffect(() => {
		if (selectedWebFlow) {
			setView('response');
		}
	}, [selectedWebFlow]);

	return (
		<DashboardContainer>
			<Typography marginBottom={2} level='h2'>Web Flows</Typography>
			<Grid container spacing={2}>
				<Grid xs={12} md={7}>
					<Paper style={{ padding: 0 }}>
						<table>
							<thead>
								<tr>
									<th><Typography level='title-sm' color='neutral'>Name</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Last updated</Typography></th>
									<th><Typography level='title-sm' color='neutral'>Number of runs</Typography></th>
								</tr>
							</thead>
							<tbody>
								{webFlows.map((wf) => (
									<UsageRow active={selectedWebFlow?.id === wf.id} key={wf.id} onClick={() => setSelectedWebFlow(wf)}>
										<td>
											<Box display='flex' alignItems='center' gap={1}>
												<img width={16} height={16} src={wf?.icon_url || '/favicon.ico'} alt={wf?.name} />
												<Typography level='title-sm'>{wf?.name}</Typography>
											</Box>
										</td>
										<td>
											<Typography level='body-sm'>{moment(wf.updated_at).fromNow()}</Typography>
										</td>
										<td>
											<Typography level='body-sm'>3</Typography>
										</td>
									</UsageRow>
								))}
							</tbody>
						</table>
					</Paper>
				</Grid>
				<Grid xs={12} md={5}>
					<Paper style={{ padding: 0 }}>
						<Section>
							<Typography level='title-md'>{selectedWebFlow?.public_id}</Typography>
						</Section>
						<Divider/>
						<Section>
							<Box marginY={1} display='grid' gridTemplateColumns='0fr 1fr' alignItems='center' rowGap={1} columnGap={2} margin={0}>
								<Typography color='neutral' level='title-sm'>Name</Typography>
								<Box display='flex' alignItems='center' gap={0.5}>
									<img width={16} height={16} src={selectedWebFlow?.icon_url || '/favicon.ico'} alt={selectedWebFlow?.name} />
									<Typography level='title-sm'>{selectedWebFlow?.name}</Typography>
								</Box>
								<Typography whiteSpace='nowrap' color='neutral' level='title-sm'>Last updated</Typography>
								<Typography level='body-sm'>{moment(selectedWebFlow?.updated_at).fromNow()}</Typography>
							</Box>
						</Section>
						<Divider/>
						<Section>
							<Box display='flex' gap={1} marginBottom={1}>
								<ToggleButtonGroup value={view} onChange={(e, v) => setView(v)}>
									<Button value='response' size='sm' color={view === 'response' ? 'secondary' : 'neutral'} variant={view === 'response' ? 'solid' : 'outlined'}>Response</Button>
									<Button value='request' size='sm' color={view === 'request' ? 'secondary' : 'neutral'} variant={view === 'request' ? 'solid' : 'outlined'}>Request</Button>
								</ToggleButtonGroup>
							</Box>
							{view === 'response' ? (
								<>
									<Typography level='title-md'>Response body</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedWebFlow?.response, null, 2)}
									</SyntaxHighlighter>
								</>
							) : (
								<>
									<Typography level='title-md'>Request parameters</Typography>
									<SyntaxHighlighter
										language='json'
										style={a11yLight}
										customStyle={{ fontSize: '12px', background: 'transparent', padding: 0, margin: 0 }}
										showLineNumbers
										wrapLongLines
									>
										{JSON.stringify(selectedWebFlow?.parameters || {}, null, 2)}
									</SyntaxHighlighter>
								</>
							)}
						</Section>
					</Paper>
				</Grid>
			</Grid>
		</DashboardContainer>
	);
};

export default WebFlowsPage;
