import React, { createContext, useEffect, useState } from 'react';
import API from '../api';

// Create the AppContext
export const AppContext = createContext<AppContextType>(null);

interface AppContextType {
}

// Create the UserProvider component
export const AppContextProvider = ({ children }: { children: React.ReactNode }) => {
	return (
		<AppContext.Provider
			value={{
			}}>
			{children}
		</AppContext.Provider>
	);
};
