import styled from '@emotion/styled';
import React, { forwardRef, useEffect, ForwardedRef, RefObject, useState, useMemo, useRef, useContext } from 'react';
import { buildListParent, buildLowestListParent, ElementData, getElementData, getElementsFromData, getMatchingListItemsFromStack } from '../util';
import { appendIFrameStyle } from './appendIFrameStyle';
import Events from '../Events';
import { List, ListItem, ListItemButton, ListItemContent, Box, Menu, MenuItem, Modal, ModalClose, ModalDialog, Typography } from '@mui/joy';
import { ReactComponent as ChevronRightIcon } from '@/public/icons/chevron-right.svg';
import { ReactComponent as MouseClickIcon } from '@/public/icons/mouse-click.svg';
import { ReactComponent as CubeIcon } from '@/public/icons/cube.svg';
import { ReactComponent as CursorTypingIcon } from '@/public/icons/cursor-typing.svg';
import { ReactComponent as ImageIcon } from '@/public/icons/image.svg';
import { ReactComponent as TextIcon } from '@/public/icons/text.svg';
import { ReactComponent as FullArrowUpIcon } from '@/public/icons/full-arrow-up.svg';
import { BuilderContext, SELECT_MODE } from '../BuilderContext';

const IFrame = styled.iframe`
		width: 100%;
		height: 100%;
		border: none;
		background-color: #fff;
`;

interface ContentIFrameProps {
	browserSession: any;
  selectMode: string | null;
  onUpdate: (update: any) => void;
  onKeyDown: (event: KeyboardEvent) => void;
  onKeyUp: (event: KeyboardEvent) => void;
  onBlur: () => void;
}

const ContentIFrame = forwardRef(({ browserSession, selectMode, onUpdate, onKeyDown, onKeyUp, onBlur }: ContentIFrameProps, ref: RefObject<HTMLIFrameElement>) => {
	const { activeAction } = useContext(BuilderContext);
	const [currentElement, setCurrentElement] = useState<HTMLElement | null>(null);
	const currentElementRef = useRef<HTMLElement | null>(null);
	const [hoveredElement, setHoveredElement] = useState<HTMLElement | null>(null);
	const [isCreatingAction, setIsCreatingAction] = useState(false);
	const [activeElements, setActiveElements] = useState<HTMLElement[]>([]);
	const [activeListElement, setActiveListElement] = useState<HTMLElement | null>(null);
	const [nonListContextElement, setNonListContextElement] = useState<HTMLElement | null>(null);
	const [listElement, setListElement] = useState<HTMLElement | null>(null);
	const [allListElements, setAllListElements] = useState<ElementData[]>([]);
	const [listItemElement, setListItemElement] = useState<HTMLElement | null>(null);
	const [currentList, setCurrentList] = useState<HTMLElement[]>([]);
	const [currentListStack, setCurrentListStack] = useState<ElementData[]>([]);
	const [doc, setDoc] = useState<Document | null>(null);
	const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight });

	const [mouseWithinIframe, setMouseWithinIframe] = useState(true);

	const selectModeRef = useRef(false);

	useEffect(() => {
		selectModeRef.current = Boolean(selectMode) || (activeAction && activeAction.type === 'extract' && activeAction.parameter.type === 'object');
	}, [selectMode, activeAction]);

	useEffect(() => {
		currentElementRef.current = currentElement;
	}, [currentElement]);


	function clearHighlights(...classNames: string[]) {
		for (const className of classNames) {
			doc?.querySelectorAll(`.${className}`).forEach(element => {
				element.remove();
			});
		}
	}

	function createHighlight(element: HTMLElement, className: string = 'shinpads-highlight', ...classes: string[]) {
		const highlight = doc.createElement('div');
		highlight.classList.add(className);
		highlight.classList.add('shinpads-overlay');
		classes.forEach(c => highlight.classList.add(c));
		doc.body.appendChild(highlight);

		const rect = element.getBoundingClientRect();
		const scrollX = doc.documentElement.scrollLeft;
		const scrollY = doc.documentElement.scrollTop;
		highlight.style.left = `${rect.left + scrollX - 2}px`;
		highlight.style.top = `${rect.top + scrollY - 2}px`;
		highlight.style.width = `${rect.width + 4}px`;
		highlight.style.height = `${rect.height + 4}px`;

		highlight.style.borderRadius = element.style.borderRadius || '4px';

		return highlight;
	}

	const onIFrameClick = (event: MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		console.log('onIFrameClick', event);
		if (selectModeRef.current && currentElementRef.current) {
			console.log('selectMode', currentElementRef.current);
			setIsCreatingAction(true);
		} else {
			onUpdate({ type: 'click', data: { x: event.clientX, y: event.clientY, shinpadsId: currentElementRef.current?.getAttribute('shinpads-id') } });
		}
	};

	const onDocLoaded = () => {
		const nDoc = ref.current.contentWindow.document;

		setDoc(nDoc);

		console.log('iframe loaded!!!!!');
		ref.current.contentWindow.focus();

		ref.current.contentWindow.addEventListener('blur', () => {
			console.log('iframe blur');
			onBlur();
		});

		appendIFrameStyle(nDoc);
		// TODO: For click and mousemove, can just get the element that is clicked/hovered to save a lot of network usage!
		nDoc.removeEventListener('click', onIFrameClick);
		nDoc.addEventListener('click', onIFrameClick);

		nDoc.addEventListener('mousemove', (event) => {
			onUpdate({ type: 'mousemove', data: { x: event.clientX, y: event.clientY } });
			const elements = nDoc.elementsFromPoint(event.clientX, event.clientY);

			const originalElement = elements.find(el => !el.classList.contains('shinpads-overlay'));

			let element = originalElement;
			while (element && element.parentElement && originalElement.getBoundingClientRect().width >= element.parentElement.getBoundingClientRect().width && originalElement.getBoundingClientRect().height >= element.parentElement.getBoundingClientRect().height) {
				element = element.parentElement;
			}

			setHoveredElement(element as HTMLElement);
		});

		nDoc.addEventListener('keydown', (event) => {
			onUpdate({ type: 'keydown', data: { key: event.key } });
			onKeyDown(event);
		});

		nDoc.addEventListener('keyup', (event) => {
			onKeyUp(event);
		});

		nDoc.addEventListener('scroll', () => {
			onUpdate({ type: 'scroll', data: { x: ref.current.contentWindow?.scrollX, y: ref.current.contentWindow?.scrollY } });
		});

		nDoc.addEventListener('input', (event: InputEvent) => {
			onUpdate({
				type: 'input',
				data: {
					value: (event.target as HTMLInputElement).value,
					shinpadsId: (event.target as HTMLInputElement).getAttribute('shinpads-id')
				}
			});
		});
	};

	useEffect(() => {
		if (ref.current) {
			const resizeObserver = new ResizeObserver(() => {
				if (ref.current) {
					const { offsetWidth, offsetHeight } = ref.current;
					setWindowSize({ width: offsetWidth, height: offsetHeight });
					onUpdate({ type: 'resize', data: { width: offsetWidth, height: offsetHeight } });
				}
			});
			resizeObserver.observe(ref.current);

			onUpdate({ type: 'resize', data: { width: ref.current.offsetWidth, height: ref.current.offsetHeight } });
			onUpdate({ type: 'scroll', data: { x: ref.current.scrollLeft, y: ref.current.scrollTop } });

			if (ref.current.getAttribute('data-shinpads-iframe')) {
				return;
			}

			ref.current.setAttribute('data-shinpads-iframe', 'true');
			ref.current.removeEventListener('load', onDocLoaded);
			ref.current.addEventListener('load', onDocLoaded);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, [ref.current]);

	useEffect(() => {
		clearHighlights('shinpads-highlight.active', 'shinpads-highlight.active-secondary', 'shinpads-highlight.active-container');
		if (activeAction) {
			const elements = getElementsFromData(doc, activeAction.element);
			console.log(elements);

			// TODO: apply filters

			if (activeAction.type === 'extract' && activeAction.parameter.type === 'object') {
				setActiveElements(elements);

				let newActiveListElement = null;
				if (elements.length > 0) {
					newActiveListElement = elements[0]?.parentElement;
					// get the lowest parent that contains all the elements
					while (elements.some(el => !newActiveListElement.contains(el))) {
						newActiveListElement = newActiveListElement?.parentElement;
					}
				}
				setActiveListElement(newActiveListElement);

				(activeAction.subActions || activeAction.subData)?.forEach(subAction => {
					const subListItems = getElementsFromData(doc, subAction.element);
					elements.forEach(el => {
						const elSubElements = subListItems.filter(el2 => el.contains(el2));
						// subAction.filters?.forEach(filter => {
						// 	elSubElements = getFilteredListItems(elSubElements, filter);
						// });
						elSubElements.forEach(el2 => {
							createHighlight(el2, 'shinpads-highlight', 'active-secondary');
						});
					});
				});

				elements.forEach(element => {
					createHighlight(element, 'shinpads-highlight', 'active-container');
				});
			} else {
				elements.forEach(element => {
					createHighlight(element, 'shinpads-highlight', 'active');
				});
			}
		}
	}, [activeAction, activeAction?.subActions, windowSize]);

	useEffect(() => {
		if (!hoveredElement) return;

		// if (activeElements.length > 0) {
		// 	// activeElements means we're inspecting an object
		// 	// TODO:
		// 	// if (!activeListElement.contains(GLOBAL.currentElement)) {
		// 	// 	clearHighlights();
		// 	// 	return;
		// 	// }

		// 	clearHighlights('shinpads-highlight');

		// 	console.log('activeListElement', activeListElement);

		// 	createHighlight(hoveredElement, 'shinpads-highlight');

		// 	// const { stack } = buildListParent(currentElement, activeListElement);
		// 	// const { items: listItems, stack: listStack } = getMatchingListItemsFromStack(activeListElement, stack);

		// 	// setListElement(activeListElement);
		// 	// setListItemElement(listItems[0]);
		// 	// setCurrentList(listItems);
		// 	// console.log('currentList', currentList);

		// 	// for (const el of listItems) {
		// 	// 	createHighlight(el, 'shinpads-highlight');
		// 	// }
		// } else {
		ref.current.contentWindow.focus();
		const elementData = getElementData(hoveredElement);
		const listItems = getElementsFromData(doc, elementData);
		const { listElement, allListElements, nonListContextElement } = buildLowestListParent(doc, hoveredElement);
		if (activeElements.length > 0) {
			// check that hovered element is inside an active list element
			if (!activeListElement.contains(hoveredElement)) {
				setCurrentElement(null);
				setCurrentList([]);
				return;
			}
		}
		setCurrentElement(hoveredElement);
		setNonListContextElement(nonListContextElement);
		setListElement(listElement);
		setListItemElement(listItems[0]);
		setCurrentList(listItems);
		setCurrentListStack([]);
		setAllListElements(allListElements as ElementData[]);
		// }
	}, [hoveredElement]);


	// HIGHLIGHTS
	useEffect(() => {
		clearHighlights('shinpads-current-element', 'shinpads-highlight.secondary');
		if (!currentElement) return;
		if (!isCreatingAction && (!selectMode || !mouseWithinIframe) && !activeListElement) return;


		createHighlight(currentElement, 'shinpads-highlight', 'shinpads-current-element');

		currentList.forEach(element => {
			if (element === currentElement) return;
			createHighlight(element, 'shinpads-highlight', 'secondary');
		});

	}, [currentElement, currentList, selectMode, mouseWithinIframe, isCreatingAction, windowSize]);

	useEffect(() => {
		// clearHighlights('shinpads-highlight.selected');
		// if (!currentElement) return;

		// createHighlight(currentElement, 'shinpads-highlight', 'selected');

		// window.dispatchEvent(new CustomEvent(Events.ON_ELEMENT_SELECTED, { detail: selectedElement }));
	}, [isCreatingAction]);

	const onCreateAction = (actionData) => {
		const action: any = {
			id: `action_${actionData.type}_${Date.now()}`,
			element: getElementData(currentElement),
			filters: [],
		};

		if (actionData.type === 'action') {
			action.type = 'action';
			action.parameter = {
				type: actionData.actionType,
				isArray: selectMode === SELECT_MODE.ALL,
				name: '',
			};
			action.subActions = [];
		} else if (actionData.type === 'extract') {
			action.type = 'extract';
			action.parameter = {
				type: actionData.paramType,
				isArray: selectMode === SELECT_MODE.ALL,
				name: '',
			};
			action.subActions = [];
		}
		window.dispatchEvent(new CustomEvent(Events.ON_CREATE_ACTION, { detail: action }));
		setIsCreatingAction(false);
	};

	const selectParent = () => {
		if (currentElement.tagName === 'BODY') {
			return;
		}
		setHoveredElement(currentElement?.parentElement);
	};

	return (
		<>
			<Modal
				open={isCreatingAction}
				onClose={() => {
					setIsCreatingAction(false);
				}}
				sx={{
					'& .MuiModal-backdrop': {
						backdropFilter: 'blur(0px)',
					},
				}}
			>
				<ModalDialog
					sx={{
						p: 1,
						backgroundColor: 'rgba(250, 250, 250, 0.75)',
						backdropFilter: 'blur(6px)',
						boxShadow: '0 1px inset var(--joy-palette-third-shadowHighColor)',
					}}
				>
					<List sx={{ p: 0 }}>
						{currentElement?.tagName !== 'BODY' && (
							<ListItem>
								<ListItemButton onClick={() => selectParent()}>
									<Box display='flex' alignItems='center' justifyContent='center'>
										<FullArrowUpIcon fill='currentColor' width={16} height={16} />
									</Box>
									<ListItemContent>
										<Typography level='title-sm'>Select Parent</Typography>
										<Typography textColor='neutral.500' level='body-xs'>Select the parent of the element</Typography>
									</ListItemContent>
								</ListItemButton>
							</ListItem>
						)}
						<ListItem>
							<ListItemButton onClick={() => onCreateAction({ type: 'action', actionType: 'click' })}>
								<Box display='flex' alignItems='center' justifyContent='center'>
									<MouseClickIcon fill='currentColor' width={16} height={16} />
								</Box>
								<ListItemContent>
									<Typography level='title-sm'>Click</Typography>
									<Typography textColor='neutral.500' level='body-xs'>Click on the element</Typography>
								</ListItemContent>
							</ListItemButton>
						</ListItem>
						<ListItem>
							<ListItemButton onClick={() => onCreateAction({ type: 'action', actionType: 'input' })}>
								<Box display='flex' alignItems='center' justifyContent='center'>
									<CursorTypingIcon fill='currentColor' width={16} height={16} />
								</Box>
								<ListItemContent>
									<Typography level='title-sm'>Input</Typography>
									<Typography textColor='neutral.500' level='body-xs'>Input on the element</Typography>
								</ListItemContent>
							</ListItemButton>
						</ListItem>
						<ListItem>
							<ListItemButton onClick={() => onCreateAction({ type: 'extract', paramType: 'object' })}>
								<Box display='flex' alignItems='center' justifyContent='center'>
									<CubeIcon fill='currentColor' width={16} height={16} />
								</Box>
								<ListItemContent>
									<Typography level='title-sm'>Extract Object</Typography>
									<Typography textColor='neutral.500' level='body-xs'>Extract object from the element</Typography>
								</ListItemContent>
							</ListItemButton>
						</ListItem>
						<ListItem>
							<ListItemButton onClick={() => onCreateAction({ type: 'extract', paramType: 'text' })}>
								<Box display='flex' alignItems='center' justifyContent='center'>
									<TextIcon fill='currentColor' width={16} height={16} />
								</Box>
								<ListItemContent>
									<Typography level='title-sm'>Extract Text</Typography>
									<Typography textColor='neutral.500' level='body-xs'>Extract text from the element</Typography>
								</ListItemContent>
							</ListItemButton>
						</ListItem>
						<ListItem>
							<ListItemButton onClick={() => onCreateAction({ type: 'extract', paramType: 'image' })}>
								<Box display='flex' alignItems='center' justifyContent='center'>
									<ImageIcon fill='currentColor' width={16} height={16} />
								</Box>
								<ListItemContent>
									<Typography level='title-sm'>Extract Image</Typography>
									<Typography textColor='neutral.500' level='body-xs'>Extract image from the element</Typography>
								</ListItemContent>
							</ListItemButton>
						</ListItem>
					</List>
				</ModalDialog>
			</Modal>
			<IFrame
				ref={ref}
				src={`/browser-session/${browserSession.public_id}`}
				onMouseEnter={() => { console.log('mouse enter'); setMouseWithinIframe(true); ref.current.contentWindow.focus(); }}
				onMouseLeave={() => setMouseWithinIframe(false)}
				onFocus={() => console.log('focus')}
				onBlur={onBlur}
			/>
		</>
	);
}
);

ContentIFrame.displayName = 'ContentIFrame';

export default ContentIFrame;
