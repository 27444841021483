import { Box, Input, Divider, Button, Select, Option, Chip, Typography } from '@mui/joy';
import React, { useEffect } from 'react';
import ParameterInput from './ParameterInput';
import Events from '../Events';

interface WebActionProps {
	action: any;
	onChange: (action: any) => void;
	onRemove: () => void;
	activeId: string;
	onSelected: (action: any) => void;
}

const WebAction = ({ action, onChange, onRemove, activeId, onSelected }: WebActionProps) => {
	const { filters, subActions } = action;
	const isActive = activeId === action.id;

	useEffect(() => {
		function handleActiveActionEvent(event) {
			const newSubActions = subActions ? [...subActions] : [];
			newSubActions.push(event.detail);
			onChange({ subActions: newSubActions });
		}

		if (isActive) {
			window.addEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		} else {
			window.removeEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		}
		return () => {
			window.removeEventListener(Events.SUB_ACTION_ADDED, handleActiveActionEvent);
		};
	}, [isActive, filters, subActions, action]);


	const onAddFilter = () => {
		const newFilter = {
			element: null,
			attribute: null,
			operator: 'equals',
			value: '',
		};
		onChange({ filters: [...(filters || []), newFilter] });
	};

	const onSelectElement = () => {
		window.dispatchEvent(new CustomEvent('select-element-event', { detail: { data: action } }));
	};
	return (
		<Box display='flex' flexDirection='column' >
			<Box
				display='flex'
				flexDirection='column'
				onClick={(e) => {
					e.stopPropagation();
					onSelected(action);
				}}>
				<ParameterInput
					onRemove={onRemove}
					isActive={isActive}
					parameter={action.parameter}
					selectedValue={`${action.element.tagName.toLowerCase()}${action.type !== 'object' ? `.${action.attribute}` : ''}`}
					onAddFilter={onAddFilter}
					onSelectElement={onSelectElement}
					onChange={(update) => {
						onChange({ ...action, parameter: { ...action.parameter, ...update } });
					}}
				/>
				{filters?.map((filter, index) => (
					<>
						<Divider />
						<Box key={index} display='grid' gap={1} gridTemplateColumns='1fr'>
							<Input
								value={filter.value}
								size='sm'
								fullWidth
								sx={{
									paddingLeft: 0,
									flexGrow: 1,
									borderRadius: 0,
									'&:before': {
										boxShadow: 'none',
									},
								}}
								variant='plain'
								placeholder='value'
								onChange={(e) => {
									const newFilters = [...filters];
									newFilters[index].value = e.target.value;
									onChange({ filters: newFilters });
								}}
								startDecorator={
									<>
										<Button size='sm' variant='plain' color='neutral' sx={{ whiteSpace: 'nowrap', borderTopRightRadius: 0, borderBottomRightRadius: 0, margin: 0, height: '100%', fontSize: '12px', paddingX: '6px' }}>
											{filter.element ? `${filter.element.tagName.toLowerCase()}.${filter.attribute}` : '-'}
										</Button>
										<Divider orientation='vertical' />
										<Select
											size='sm'
											variant='plain'
											color='neutral'
											sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, borderRadius: 0, overflow: 'hidden' }}
											defaultValue='equals'
											indicator={null}
											renderValue={(value) => {
												return <Chip size='sm' variant='outlined' color='neutral'>{value.label}</Chip>;
											}}
											value={filter.operator}
											onChange={(e, newValue) => {
												const newFilters = [...filters];
												newFilters[index].operator = newValue;
												onChange({ filters: newFilters });
											}}
										>
											<Option value='exists'><Chip size='sm' variant='outlined' color='neutral'>Exists</Chip></Option>
											<Option value='equals'><Chip size='sm' variant='outlined' color='neutral'>Equals</Chip></Option>
											<Option value='contains'><Chip size='sm' variant='outlined' color='neutral'>Contains</Chip></Option>
											<Option value='regex'><Chip size='sm' variant='outlined' color='neutral'>Regex</Chip></Option>
										</Select>
										<Divider orientation='vertical' />
									</>
								}
							/>
						</Box>
					</>
				))}
			</Box>
			{action.parameter?.type === 'object' && subActions?.length > 0 && (
				<>
					<Divider />
					<Box display='flex' flexDirection='column' paddingLeft={2} bgcolor='#E4E4E4'>
						<>
							{subActions?.map((subAction, index) => (
								<>
									<WebAction
										key={subAction.id}
										action={subAction}
										activeId={activeId}
										onSelected={onSelected}
										onChange={(update) => {
											const newSubActions = [...subActions];
											newSubActions[index] = { ...newSubActions[index], ...update };
											onChange({ subActions: newSubActions });
										}}
										onRemove={() => {
											const newSubActions = [...subActions];
											newSubActions.splice(index, 1);
											onChange({ subActions: newSubActions });
										}}
									/>
									{index < subActions.length - 1 && <Divider />}
								</>
							))}
						</>
					</Box>
				</>
			)}
		</Box>
	);
};

export default WebAction;
